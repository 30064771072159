/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { NextSeo } from 'next-seo';
import { useWindowScroll, useThrottledFn } from 'beautiful-react-hooks';
import PropTypes from 'prop-types';

// Server-side
import { getPageData } from 'src/pages/api/service/v1/page/[requestPageType]';
import metaModel from 'src/lib/models/metadata/static';

// Client-side
import useScrollTracking from 'src/lib/hooks/useScrollTracking';

import Container from 'src/components/global/layouts/Container';
import Hero from 'src/components/hero/Hero';
import Latest from 'src/components/global/latest/Latest';
import SectionHeader from 'src/components/global/section-headers/Static';
import Trending from 'src/components/article/Trending';
import Divider from 'src/components/global/dividers/Divider';
import CategoryHighlights from 'src/components/category/Highlights';
import PageTargeting from 'src/components/ads/PageTargeting';

// Advertising
import FullAdvertisingWrapper from 'src/components/ads/wrappers/FullAdvertisingWrapper';

const Index = ({
  metadata,
  heroContent,
  latestContent,
  trendingContent,
  categoryContent,
}) => {
  const scrollTracking = useScrollTracking();

  useWindowScroll(
    useThrottledFn(() => {
      scrollTracking.track();
    })
  );

  return (
    <>
      <PageTargeting featureId="false" pageType="home" />
      <NextSeo
        title={metadata.title}
        description={metadata.description}
        canonical="https://www.uexpress.com"
        openGraph={{
          title: metadata.title,
          description: metadata.description,
        }}
      />

      <h1 className="sr-only">UExpress Home</h1>

      <Hero content={heroContent} />

      <FullAdvertisingWrapper padded />

      <Container element="section">
        <SectionHeader priority={2} centeredMobile padded>
          Practical Advice from your Favorite Columnists
        </SectionHeader>
        <Latest
          featureAvatarUrl={
            latestContent.features[0].feature.featureAvatarUrls.small
          }
          featureName={latestContent.features[0].feature.featureName}
          featureShortName={latestContent.features[0].feature.featureShortName}
          category={latestContent.features[0].feature.category}
          creators={latestContent.features[0].feature.creators}
          bylineOverride={latestContent.features[0].feature.bylineOverride}
          articles={latestContent.features[0].articles}
          clickLocation="latest"
          clickPosition={1}
        />
        <Divider padded />
        <Latest
          featureAvatarUrl={
            latestContent.features[1].feature.featureAvatarUrls.small
          }
          featureName={latestContent.features[1].feature.featureName}
          featureShortName={latestContent.features[1].feature.featureShortName}
          category={latestContent.features[1].feature.category}
          creators={latestContent.features[1].feature.creators}
          bylineOverride={latestContent.features[1].feature.bylineOverride}
          articles={latestContent.features[1].articles}
          clickLocation="latest"
          clickPosition={2}
        />
      </Container>

      <FullAdvertisingWrapper padded />

      <Container element="section">
        <Trending content={trendingContent} header="Today's Spotlight" />
        <Divider padded />
      </Container>

      <Container element="section">
        <CategoryHighlights category="life" content={categoryContent[0]} />
      </Container>

      <FullAdvertisingWrapper padded />

      <Container element="section">
        <CategoryHighlights category="parenting" content={categoryContent[1]} />
        <Divider padded />
      </Container>

      <Container element="section">
        <CategoryHighlights category="home" content={categoryContent[2]} />
      </Container>

      <FullAdvertisingWrapper padded />

      <Container element="section">
        <CategoryHighlights category="pets" content={categoryContent[3]} />
        <Divider padded />
      </Container>

      <Container element="section">
        <CategoryHighlights category="health" content={categoryContent[4]} />
      </Container>

      <FullAdvertisingWrapper padded />

      <Container element="section">
        <CategoryHighlights category="astrology" content={categoryContent[5]} />
        <Divider padded />
      </Container>

      <Container element="section">
        <CategoryHighlights category="oddities" content={categoryContent[6]} />
      </Container>
    </>
  );
};

Index.propTypes = {
  metadata: PropTypes.objectOf(PropTypes.string).isRequired,
  heroContent: PropTypes.object.isRequired,
  latestContent: PropTypes.objectOf(PropTypes.array).isRequired,
  trendingContent: PropTypes.object.isRequired,
  categoryContent: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export async function getServerSideProps() {
  const pageData = await getPageData({
    requestPageType: 'home',
  }).then(async (response) => {
    const data = await response.json();
    return { status: response.status, data };
  });

  const metadata = metaModel('home');

  return {
    props: {
      metadata,
      heroContent: pageData.data.hero,
      latestContent: pageData.data.latest,
      trendingContent: pageData.data.trending,
      categoryContent: pageData.data.categories,
    },
  };
}

export default Index;
